<template>
  <!-- style="height: '100%',filter: grayscale(1)" -->
  <div id="app" style="height: 100%">
    <!-- 手机扫码页面 -->
    <div v-if="$route.path == '/reportDetail'">
      <router-view />
    </div>
    <!-- 网站页面 -->
    <div v-else :style="{height: ['/list','/listReport'].includes($route.path)?'':'100%'}">
      <div style="height: 152px; line-height: 152px; padding-left: 17%">
        <img src="./assets/nav.png" height="139" />
      </div>
      <div class="content">
        <div style="height: 56px;background: #007BC7;">
          <el-menu :default-active="activeIndex" class="el-menu-demo" @select="selectMenu" mode="horizontal" text-color="#fff" active-text-color="#fff">
            <el-menu-item index="1">首页</el-menu-item>
            <el-menu-item index="2" class="hoverMenuP">
              <div class="hoverMenu">
                公司概况
                <div class="el-popover el-popper_xy">
                  <p :class="{'active': activeIndex == 2}" @click="goContent('2')">发展历程</p>
                  <p :class="{'active': activeIndex == 3}" @click="goContent('3')">授权机构</p>
                  <p :class="{'active': activeIndex == 8}" @click="goContent('8')">组织机构</p>
                  <p :class="{'active': activeIndex == 5}" @click="goContent('5')">资源条件</p>
                  <p :class="{'active': activeIndex == 6}" @click="goContent('6')">人力资源</p>
                  <p style="margin-bottom: 0" :class="{'active': activeIndex == 10}" @click="goContent('10')">送检流程</p>
                  <div class="arrow popper__arrow"></div>
                </div>
              </div>
            </el-menu-item>
            <el-menu-item index="4">业务范围</el-menu-item>
            <el-menu-item index="7" class="hoverMenuP">
              <div class="hoverMenu">
                报告证书查询
                <div class="el-popover el-popper_xy">
                  <p :class="{'active': $route.path == '/explosionProofInlet'}" @click="$route.path!='/explosionProofInlet' && $router.push('/explosionProofInlet')">防爆证查询</p>
                  <p style="margin-bottom: 0" :class="{'active': $route.path == '/reportInlet'}" @click="$route.path!='/reportInlet' && $router.push('/reportInlet')">报告查询</p>
                  <div class="arrow popper__arrow"></div>
                </div>
              </div>
            </el-menu-item>
            <el-menu-item index="9">联系我们</el-menu-item>
          </el-menu>
        </div>
        <router-view />
        <home v-if="activeIndex == 1 && $route.path == '/'"></home>
        <develop v-if="activeIndex == 2 && $route.path == '/'"></develop>
        <authorized v-if="activeIndex == 3 && $route.path == '/'"></authorized>
        <business v-if="activeIndex == 4 && $route.path == '/'"></business>
        <resCondition v-if="activeIndex == 5 && $route.path == '/'"></resCondition>
        <personResource v-if="activeIndex == 6 && $route.path == '/'"></personResource>
        <organization v-if="activeIndex == 8 && $route.path == '/'"></organization>
        <contactUs v-if="activeIndex == 9 && $route.path == '/'"></contactUs>
        <inspection v-if="activeIndex == 10 && $route.path == '/'"></inspection>
        <div v-if="$route.path == '/reportInlet'" class="statement">
          <p class="title">[声明]</p>
          <p>1、本查询服务只限于我机构出具的检验检测报告查询。</p>
          <p>2、由于涉及客户信息的保密性原则，本查询系统仅可证实您所查询报告号的有效性，和基本的产品信息。如需完整的检验报告信息或需进一步确认有效性，请联系相关的检验部门。</p>
          <p>3、本系统信息仅用于客户查询核对报告真伪，不做其他用途。本查询数据的最终解释权归公司所有。</p>
        </div>
      </div>
      <div class="footer">
        <p class="tc" style="padding-top: 28px">
          联系电话：（023）65239415、65239204&nbsp;&emsp;<span class="borderL">邮政编码：401325</span><span class="borderL">Email: GJZJ@vip163.com</span><span class="borderL">公司地址：重庆市大渡口区石林大道6号（煤科院建桥基地）</span>
        </p>
        <p class="tc" style="padding-top: 14px; color: #6b94b1">
          <a target="_blank" href="https://beian.miit.gov.cn/">渝ICP备16013788号-1</a>&nbsp;&nbsp;重庆安标院有限公司版权所有&nbsp;&emsp;<span class="borderL">技术支持：<a target="_blank" href="http://www.qzsoft.net/">成都青软青之软件有限公司</a></span>
        </p>
      </div>
    </div>
  </div>
</template>
<script>
const home = () => import("./views/components/home.vue");
const develop = () => import("./views/components/develop.vue");
const authorized = () => import("./views/components/authorized.vue");
const business = () => import("./views/components/business.vue");
const resCondition = () => import("./views/components/resCondition.vue");
const personResource = () => import("./views/components/personResource.vue");
const contactUs = () => import("./views/components/contactUs.vue");
const organization = () => import("./views/components/organization.vue");
const inspection = () => import("./views/components/inspection.vue");
export default {
  components: {
    home,
    develop,
    authorized,
    business,
    resCondition,
    personResource,
    contactUs,
    organization,
    inspection
  },
  data() {
    return {
      activeIndex: '0'
    }
  },
  mounted() {
    const path = window.location.hash, keys = window.location.hash.split("?keys=").length > 0 ? window.location.hash.split("?keys=")[1] : '';
    if (this.$route.path == '/') {
      this.activeIndex = !keys ? '1' : keys;
    }
    if (['#/reportInlet','#/explosionProofInlet'].includes(path) || path.indexOf('/list') != -1) {
      this.activeIndex = '7'
    }
  },
  methods: {
    selectMenu(index) {
      if (index != '7' && index != '2') {
        if (this.$route.path == '/' && this.$route.query.keys === index) return
        this.activeIndex = index;
        this.$router.push("/?keys=" + index);
      }
    },
    goContent(key){
      this.activeIndex = key;
      this.$router.push("/?keys=" + key);
    }
  }
}
</script>
<style lang="scss">
@import "./assets/style.scss";
</style>
