import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/explosionProofInlet",
    name: "explosionProofInlet",
    component: () => import("../views/explosionProofInlet.vue")
  },
  {
    path: "/list",
    name: "list",
    component: () => import("../views/list.vue")
  },
  {
    path: "/reportInlet",
    name: "reportInlet",
    component: () => import("../views/reportInlet.vue")
  },
  {
    path: "/listReport",
    name: "listReport",
    component: () => import("../views/list_report.vue")
  },
  {
    path: "/reportDetail",
    name: "reportDetail",
    component: () => import("../views/reportDetail.vue")
  }
];

const router = new VueRouter({
  routes
});

export default router;
