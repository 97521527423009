import 'babel-polyfill';
import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import { post, fetch } from "./store/request";

Vue.config.productionTip = false;

import "element-ui/lib/theme-chalk/index.css";
import {
    Pagination,
    Dialog,
    Input,
    Select,
    Option,
    Button,
    Table,
    TableColumn,
    Form,
    FormItem,
    Tabs,
    TabPane,
    Message,
    Loading,
    Image,
    Menu,
    MenuItem,
    Timeline,
    TimelineItem,
    Popover,
    Row,
    Col
} from "element-ui";

Vue.use(Table);
Vue.use(TableColumn);
Vue.use(Pagination);
Vue.use(Dialog);
Vue.use(Input);
Vue.use(Select);
Vue.use(Option);
Vue.use(Button);
Vue.use(Form);
Vue.use(FormItem);
Vue.use(Tabs);
Vue.use(TabPane);
Vue.use(Loading);
Vue.use(Image);
Vue.use(Menu);
Vue.use(MenuItem);
Vue.use(Timeline);
Vue.use(TimelineItem);
Vue.use(Popover);
Vue.use(Row);
Vue.use(Col);

Vue.prototype.$message = Message;
Vue.prototype.$post = post;
Vue.prototype.$fetch = fetch;
Vue.prototype.$requestUrl = window.location.protocol + '//' + window.location.hostname + (window.location.port ? ':' + window.location.port : '') + '/';
// Vue.prototype.$requestUrl = "https://www.cqabjc.com/";
// Vue.prototype.$requestUrl = "http://192.168.4.124:8087/";
new Vue({
    router,
    store,
    render: (h) => h(App),
}).$mount("#app");