import axios from "axios";
import { Message } from "element-ui";
import store from "./index";

axios.defaults.responseType = "json";
//http request 拦截器
axios.interceptors.request.use(
  (config) => {
    return config;
  },
  (error) => {
    store.state.tableLoading = false;
    return Promise.reject(error);
  }
);

//http response 拦截器
axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    store.state.tableLoading = false;
    Message({
      message: error,
      type: "error",
      duration: 5 * 1000,
    });
    return Promise.reject(error);
  }
);

/**
 * 封装get方法
 * @param url
 * @param data
 * @returns {Promise}
 */

export async function fetch(url, params = {}) {
  return await new Promise((resolve, reject) => {
    axios
      .get(url, {
        params: params,
      })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

/**
 * 封装post请求
 * @param url
 * @param data
 * @returns {Promise}
 */
export function post(url, data = {}, headers) {
  return new Promise((resolve, reject) => {
    axios.post(url, data, headers).then(
      (response) => {
        resolve(response.data);
      },
      (error) => {
        reject(error);
      }
    );
  });
}
